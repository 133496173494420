import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 16,
      width: '100%',
      maxWidth: 800,
    },
    '& .MuiTypography-h6': {
      fontWeight: 600,
    },
  },
  form: {
    margin: '12px 0 8px',
    '& .MuiGrid-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiTextField-root': {
      width: 'calc(100% - 16px)',
      minHeight: 67,
    },
  },
  label: {
    color: fade(theme.palette.text.primary, 0.54),
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
  button: {
    padding: '8px 25px',
    fontWeight: 700,
  },
}));

export default useStyles;
